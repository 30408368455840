<!-- eslint-disable max-len -->
<template>
  <div>
    <v-col cols="12" md="12" lg="12" sm="12" xs="12" class="margin-top-large">
      <carousel-3d
        :show-arrows="false"
        hide-delimiter-background
        space="350"
        perspective="0"
        @after-slide-change="onAfterSlideChange"
        @before-slide-change="onBeforeSlideChange"
        v-model="selectedCardIndex"
        width="380"
        height="460"
        :controls-visible="true"
        :controls-width="5"
        :controls-height="5"
        :clickable="false"
        class="carousel-container"
      >
        <slide
          v-for="(item, index) in itemsBenefits"
          :index="index"
          :key="item.index"
          :class="index === selectedCardIndex ? 'selected' : 'custom-card'"
        >
          <v-card
            width="380"
            height="460"
            :class="index === selectedCardIndex && 'selected'"
          >
            <v-card-text>
              <v-row class="d-flex justify-center align-center">
                <div v-if="index === selectedCardIndex">
                  <v-img
                    :src="
                      require(`../../../assets/newlanding/Benefit/${item.icon}.svg`)
                    "
                    :style="{ 'max-width': item.maxWidth }"
                    class="transparent-image"
                  ></v-img>
                </div>
                <div v-if="index != selectedCardIndex">
                  <v-img
                    :src="
                      require(`../../../assets/newlanding/Benefit/${item.iconDisabled}.svg`)
                    "
                    :style="{ 'max-width': item.maxWidth }"
                    class="mb-5 mt-4 transparent-image"
                  ></v-img>
                </div>
              </v-row>
            </v-card-text>
            <v-row class="d-flex justify-center align-center">
              <h2 class="text-title text-center pa-2">{{ item.title }}</h2>
            </v-row>
            <v-row>
              <p class="text-description text-center">
                {{ item.description }}
              </p>
            </v-row>
          </v-card>
        </slide>
      </carousel-3d>
    </v-col>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  name: 'BenefitsWeb',
  components: {
    Carousel3d,
    Slide,
  },
  methods: {
    onAfterSlideChange(index) {
      this.selectedCardIndex = index;
    },
    onBeforeSlideChange(index) {
      this.selectedCardIndex = index;
    },
  },

  data() {
    return {
      selectedCardIndex: 0,
      itemsBenefits: [
        {
          icon: 'connected',
          iconDisabled: 'connecteddisabled',
          maxWidth: '174.55px',
          title: 'Konectados contigo',
          description:
            'Programa de beneficios pensado en tu bienestar y en el de tu familia.',
        },
        {
          icon: 'hour',
          iconDisabled: 'hourdisabled',
          maxWidth: '112px',
          title: 'Horarios flexibles',
          description:
            'Tenemos diferentes horarios para que puedas adaptar a tu estilo de vida, ya sea que estudies o tengas otras actividades.',
        },
        {
          icon: 'discounts',
          iconDisabled: 'discountsdisabled',
          maxWidth: '125.23px',
          title: 'Descuentos corporativos',
          description:
            'Disfruta descuentos en actividades de entretenimiento, belleza, deporte, educación, salud, viajes y más.',
        },
        {
          icon: 'conventions',
          iconDisabled: 'conventionsdisabled',
          maxWidth: '159.75px',
          title: 'Convenios',
          description:
            'Accede a los mejores convenios corporativos por ser Talento K, en categorías como: educación, salud y entretenimiento.',
        },
        {
          icon: 'career',
          iconDisabled: 'careerdisabled',
          maxWidth: '84.32px',
          title: 'Línea de carrera',
          description:
            'Tienes diversas oportunidades para crecer dentro de la empresa, en diferentes posiciones, cuentas o campañas. El 75% de vacantes se cubren con personal interno.',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";
.content-color {
  background-color: $secondary-white;
  width: 100%;
}
.carousel-container {
  width: 100%;
}
.custom-card {
  border-radius: 20px;
  border: none;
  background: $principal-white;
  background-color: $step-gray-dark;
  box-shadow: 0px 8px 12px $step-black-dark;
  .transparent-image {
    opacity: 0.7;
    filter: blur(5px);
    margin-top: 73px !important;
  }
  .text-title {
    filter: blur(3px);
    font-family: "Barlow", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    background-color: $step-gray;
    opacity: 0.1;
    text-shadow: 0 0 10px $step-black-dark;
    margin-top: 32px;
  }
  .text-description {
    filter: blur(3px);
    font-family: "Barlow", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    background-color: $step-gray;
    opacity: 0.2;
    text-shadow: 0 0 10px $step-black-dark;
    margin-top: 32px;
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
}
.selected {
  background: $background-color-gradient;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 8px 12px $step-black-dark;
   .transparent-image {
    margin-top: 73px;
  }
  .text-title {
    font-family: "Barlow", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: $principal-white;
    margin-top: 32px;
  }
  .text-description {
    font-family: "Barlow", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    color: $principal-white;
    margin-top: 32px;
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
}
.text-title {
  font-family: "Barlow", sans-serif !important;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
}
.text-description {
  font-family: "Barlow", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
}
@media (max-width: 1920px) {
  .margin-top-large {
    margin-top: 64px;
  }
}
@media (max-width: 1440px) {
  .margin-top-large {
    margin-top: 14px;
  }
}
@media (max-width: 1366px) {
  .margin-top-large {
    margin-top: -44px;
  }
}
@media (max-width: 1024px) {
  .margin-top-large {
    margin-top: -32px;
  }
}
@media (max-width: 688px) {
  .carousel-container {
    text-align: center;
  }
}
@media (max-width: 360px) {

}
</style>
