<!-- eslint-disable max-len -->
<template>
  <v-col cols="12" md="12" lg="12" sm="12" xs="12" class="margin-top-md">
    <carousel-3d
      :show-arrows="false"
      hide-delimiter-background
      space="218"
      perspective="0"
      @after-slide-change="onAfterSlideChange"
      @before-slide-change="onBeforeSlideChange"
      v-model="selectedCardIndex"
      width="250"
      height="362"
      :controls-visible="false"
      :controls-width="5"
      :controls-height="5"
      :clickable="false"
      class="carousel-container-mobile"
    >
      <slide
        v-for="(item, index) in itemsBenefits"
        :index="index"
        :key="item.index"
        :class="
          index === selectedCardIndex ? 'selected' : 'custom-card'
        "
      >
        <v-card
          width="250"
          height="362"
          :class="index === selectedCardIndex && 'selected'"
        >
         <v-card-text class="d-flex justify-center align-center">
              <div v-if="index === selectedCardIndex">
                <v-img
                  :src="
                    require(`../../../assets/newlanding/Benefit/${item.icon}.svg`)
                  "
                  :style="{ 'max-width': item.maxWidth }"
                  class="transparent-image"
                ></v-img>
              </div>
              <div v-if="index != selectedCardIndex">
                <v-img
                  :src="
                    require(`../../../assets/newlanding/Benefit/${item.iconDisabled}.svg`)
                  "
                  :style="{ 'max-width': item.maxWidth }"
                  class="transparent-image"
                ></v-img>
              </div>
            </v-card-text>
          <h2 class="text-title text-center">
            {{ item.title }}
          </h2>
          <p class="text-description text-center">
            {{ item.description }}
          </p>
        </v-card>
      </slide>
    </carousel-3d>
  </v-col>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  name: 'BenefitsPage',
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      selectedCardIndex: 0,
      itemsBenefits: [
        {
          icon: 'connected',
          iconDisabled: 'connecteddisabled',
          maxWidth: '116.36px',
          title: 'Konectados contigo',
          description:
            'Programa de beneficios pensado en tu bienestar y en el de tu familia.',
        },
        {
          icon: 'hour',
          iconDisabled: 'hourdisabled',
          maxWidth: '80px',
          title: 'Horarios flexibles',
          description:
            'Tenemos diferentes horarios para que puedas adaptar a tu estilo de vida, ya sea que estudies o tengas otras actividades.',
        },
        {
          icon: 'discounts',
          iconDisabled: 'discountsdisabled',
          maxWidth: '83.49px',
          title: 'Descuentos corporativos',
          description:
            'Disfruta descuentos en actividades de entretenimiento, belleza, deporte, educación, salud, viajes y más.',
        },
        {
          icon: 'conventions',
          iconDisabled: 'conventionsdisabled',
          maxWidth: '120px',
          title: 'Convenios',
          description:
            'Accede a los mejores convenios corporativos por ser Talento K, en categorías como: educación, salud y entretenimiento.',
        },
        {
          icon: 'career',
          iconDisabled: 'careerdisabled',
          maxWidth: '56.21px',
          title: 'Línea de carrera',
          description:
            'Tienes diversas oportunidades para crecer dentro de la empresa, en diferentes posiciones, cuentas o campañas. El 75% de vacantes se cubren con personal interno.',
        },
      ],
    };
  },
  methods: {
    onAfterSlideChange(index) {
      this.selectedCardIndex = index;
    },
    onBeforeSlideChange(index) {
      this.selectedCardIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";
.content-color {
  background-color: $secondary-white;
  width: 100%;
}
.carousel-container {
  width: 100%;
}
.custom-card {
  border-radius: 20px;
  border: none;
  background: $principal-white;
  background-color: $principal-greyLight;
  box-shadow: 0px 8px 12px $step-black-medium;
  .transparent-image {
    opacity: 0.7;
    filter: blur(3px);
    margin-top: 50px !important;
  }
  .text-title {
    font-family: "Barlow", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    background-color: $step-gray;
    opacity: 0.1;
    text-shadow: 0 0 10px $step-black-dark;
    margin-top: 10px;
  }
  .text-description {
    font-family: "Barlow", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    background-color: $step-gray;
    opacity: 0.2;
    text-shadow: 0 0 10px $step-black-dark;
    margin-top: 22px;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}
.selected {
  background: $background-color-gradient;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 8px 12px $step-black-medium;
   .transparent-image {
    margin-top: 50px !important;
  }
  .text-title {
    font-family: "Barlow", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: $principal-white;
    margin-top: 10px;
  }
  .text-description {
    font-family: "Barlow", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $principal-white;
    margin-top: 32px;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}
.text-title {
  font-family: "Barlow", sans-serif !important;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
}
.text-description {
  font-family: "Barlow", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
}
@media (max-width: 688px) {
  .carousel-container {
    text-align: center;
  }
  .margin-top-md {
   margin-top: 62px;
}
}
@media (max-width: 414px) {
  .margin-top-md {
   margin-top: -8px;
}
}
@media (max-width: 360px) {
  .margin-top-md {
   margin-top: -50px;
}
}
</style>
