<!-- eslint-disable max-len -->
<template>
  <v-container class="carrusels fullscreen-div" fluid>
    <v-row class="d-flex flex-column">
      <v-col
        cols="12"
        md="12"
        lg="12"
        sm="12"
        xs="12"
        class="d-flex justify-center"
      >
        <p class="text-center textBenefit">Beneficios</p>
      </v-col>
      <div class="hidden-sm-and-down">
        <BenefitsWeb />
      </div>
      <div class="hidden-md-and-up">
        <BenefitsMobile />
      </div>
    </v-row>
  </v-container>
</template>

<script>
import BenefitsWeb from './BenefitsWeb.vue';
import BenefitsMobile from './BenefitsMobile.vue';

export default {
  name: 'BenefitsPage',
  components: {
    BenefitsWeb,
    BenefitsMobile,
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";
.fullscreen-div {
  background-color: $secondary-white !important;
  height: 100%;
  overflow: hidden;
}
.textBenefit {
  font-size: 48px;
  font-weight: 600;
  font-family: $principal-font !important;
  color: $secondary-blue;
}
@media (max-width: 1920px) {
  .textBenefit {
    font-size: 48px;
    margin-top: 58px;
  }
}
@media (max-width: 1366px) {
  .textBenefit {
    margin-top: 50px;
  }
}
@media (max-width: 1024px) {
  .textBenefit {
    font-size: 48px;
    margin-top: 50px;
  }
}
@media (max-width: 720px) {
  .textBenefit {
    margin-top: 50px;
    margin-bottom: 120px;
  }
}
@media (max-width: 688px) {
  .textBenefit {
    font-size: 39px;
    margin-bottom: 64px;
  }
}
@media (max-width: 414px) {
  .textBenefit {
    font-size: 32px;
    margin-top: 34px;
  }
}
@media (max-width: 360px) {
  .textBenefit {
    font-size: 32px;
    margin-top: 22px;
  }
}
</style>
